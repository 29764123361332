<template>
<v-container fluid fill-height v-if="show">
    <v-card :class="`align-self-stretch ` + ($vuetify.breakpoint.mdAndUp ? `pa-4 ` : `pa-0`)" :flat="$vuetify.breakpoint.mdAndUp ? true : false" :elevation="$vuetify.breakpoint.mdAndUp ? `1` : `0`" width="100%">
        <v-card-title class="px-0">
            <h1>Точки доставки</h1>
        </v-card-title>
        <v-alert max-width="700" type="error" outlined class="my-6 mx-auto" v-if="contactInfo.userId ? (contactInfo.isConfirmed ? false : true) : false">
            Немає доступу до інформації. Ваші контактні дані не підтверджені менеджером.
        </v-alert>
        <v-alert max-width="700" type="error" outlined class="my-6 mx-auto" v-if="!contractors.length && loaded">
            Немає жодного доступного контрагента для перегляду данних.
        </v-alert>
        <v-data-table class="my-4" item-value="id" show-expand :expanded.sync="expanded" hide-default-footer multi-sort :headers="filteredHeaders" item-key="id" :item-class="rowClasses" disable-pagination :items="consigneesData" mobile-breakpoint="960">
            <!-- Слот для кастомізації іконки розгортання -->
            <template v-slot:[`item.data-table-expand`]="{ isExpanded, expand, item }">
                <div class="d-flex">
                    <v-btn class="mr-2" icon title="Редагувати" @click="editCard(item)" v-if="$vuetify.breakpoint.mdAndUp" :disabled="item.status == 'closed' || contactInfo.realUserId ? true : false">
                        <v-icon class="green--text">mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn icon @click="toggleExpand(item, isExpanded, expand)" :disabled="item.status == 'closed' || contactInfo.realUserId ? true : false">
                        <v-badge :color="item.status == 'closed' || contactInfo.realUserId ? `grey` : `primary`" :value="consigneesRecipients[item.id]?.length ? 1 : 0" :content="consigneesRecipients[item.id]?.length" overlap class="ml-2">
                            <v-icon size="30" class="d-flex align-center justify-center" title="Довірені одержувачі по QR-коду">
                                mdi-qrcode-edit
                            </v-icon>
                        </v-badge>
                    </v-btn>
                </div>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
                <v-card v-if="$vuetify.breakpoint.smAndDown" tale flat width="100%" class="pa-4 mr-0 ml-auto body-2 blue lighten-5">
                    <div class="text-center body-1"><b>Довірені одержувачі на точці:</b></div>
                    <v-simple-table :style="$vuetify.breakpoint.xs ? `width: 100%` : `width: 60%`" class="mx-auto transparent">
                        <tbody>
                            <span v-for="recipient in consigneesRecipients[item.id]" :key="recipient.id" class="pt-6">
                                <tr>
                                    <th class="text-right" width="30%">ПІБ</th>
                                    <td :class="`text-left pl-2 ` + (recipient.expired ? `grey--text` : ``)" width="70%">{{ recipient.pib }}</td>
                                    <td class="text-center" rowspan="5">
                                        <v-icon v-if="recipient.expired" class="grey--text mb-1">mdi-eye-off-outline</v-icon>
                                        <v-btn :disabled="recipient.status == 'closed' || contactInfo.realUserId ? true : false" icon v-if="!recipient.expired" :loading="loadingPdf[recipient.id]" title="Завантажити QR-код" @click="getRecipientPdf(recipient, item)">
                                            <v-icon class="link--text mb-1">mdi-file-pdf-box</v-icon>
                                        </v-btn>
                                        <v-btn icon title="Продовжити термін дії" class="mb-1" @click="extendRecipient(recipient.id)" :disabled="recipient.status == 'closed' || contactInfo.realUserId ? true : false">
                                            <v-icon class="green--text">mdi-timer-refresh-outline</v-icon>
                                        </v-btn>
                                        <v-btn icon v-if="!recipient.expired" title="Вимкнути QR-код" @click="cancelRecipient(recipient.id)" :disabled="recipient.status == 'closed' || contactInfo.realUserId ? true : false">
                                            <v-icon class="primary--text mb-1">mdi-timer-pause-outline</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                                <tr>
                                    <th class="text-right" width="30%">Посада</th>
                                    <td :class="`text-left pl-2 ` + (recipient.expired ? `grey--text` : ``)" width="70%">{{ recipient.position }}</td>
                                </tr>
                                <tr>
                                    <th class="text-right" width="30%">Телефон</th>
                                    <td :class="`text-left pl-2 ` + (recipient.expired ? `grey--text` : ``)" width="70%">{{ recipient.phone }}</td>
                                </tr>
                                <tr>
                                    <th class="text-right" width="30%">Створено</th>
                                    <td :class="`text-left pl-2 ` + (recipient.expired ? `grey--text` : ``)" width="70%">{{ recipient.cdate }}</td>
                                </tr>
                                <tr>
                                    <th class="text-right" width="30%">Термін дії</th>
                                    <td :class="`text-left pl-2 ` + (recipient.expired ? `grey--text` : ``)" width="70%">{{ recipient.expire_date }}</td>
                                </tr>
                            </span>
                        </tbody>
                    </v-simple-table>
                    <div class="text-right mt-4">
                        <v-btn text small title="Додати QR-код" color="primary" @click="openRecipient(item.id)" :disabled="item.status == 'active' && item.typhoon_id > 0 && !contactInfo.realUserId ? false : true">
                            <v-icon>mdi-plus</v-icon>Додати QR-код
                        </v-btn>
                    </div>
                </v-card>
                <td v-if="$vuetify.breakpoint.mdAndUp" :colspan="headers.length" class="pa-4 pb-8">
                    <v-card tale flat :width="$vuetify.breakpoint.mdAndUp ? `90%` : `100%`" class="mr-0 ml-auto">
                        <b>Довірені одержувачі на точці:</b>
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-center" width="10%">
                                            <v-icon small>mdi-qrcode</v-icon>
                                        </th>
                                        <th class="text-center">ПІБ</th>
                                        <th class="text-center">Посада</th>
                                        <th class="text-center">Телефон</th>
                                        <th class="text-center">Створено</th>
                                        <th class="text-center">Термін дії</th>
                                        <th class="text-center" width="10%"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="recipient in consigneesRecipients[item.id]" :key="recipient.id">
                                        <td class="text-center">
                                            <v-icon small v-if="recipient.expired" class="grey--text mb-1">mdi-eye-off-outline</v-icon>
                                            <v-btn :disabled="recipient.status == 'closed' || contactInfo.realUserId ? true : false" icon v-if="!recipient.expired" :loading="loadingPdf[recipient.id]" title="Завантажити QR-код" @click="getRecipientPdf(recipient, item)">
                                                <v-icon class="link--text mb-1">mdi-file-pdf-box</v-icon>
                                            </v-btn>
                                        </td>
                                        <td :class="`text-center ` + (recipient.expired ? `grey--text` : ``)">{{ recipient.pib }}</td>
                                        <td :class="`text-center ` + (recipient.expired ? `grey--text` : ``)">{{ recipient.position }}</td>
                                        <td :class="`text-center ` + (recipient.expired ? `grey--text` : ``)">{{ recipient.phone }}</td>
                                        <td :class="`text-center ` + (recipient.expired ? `grey--text` : ``)">{{ recipient.cdate }}</td>
                                        <td :class="`text-center ` + (recipient.expired ? `grey--text` : ``)">{{ recipient.expire_date }}</td>
                                        <td class="text-center">
                                            <!-- Продовжити термін дії -->
                                            <v-btn icon title="Продовжити термін дії" class="mb-1" @click="extendRecipient(recipient.id)" :disabled="recipient.status == 'closed' || contactInfo.realUserId ? true : false">
                                                <v-icon class="green--text">mdi-timer-refresh-outline</v-icon>
                                            </v-btn>
                                            <v-btn icon v-if="!recipient.expired" title="Вимкнути QR-код" @click="cancelRecipient(recipient.id)" :disabled="recipient.status == 'closed' || contactInfo.realUserId ? true : false">
                                                <v-icon class="primary--text mb-1">mdi-timer-pause-outline</v-icon>
                                            </v-btn>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <v-btn text small title="Додати QR-код" color="primary" @click="openRecipient(item.id)" :disabled="item.status == 'active' && item.typhoon_id > 0 && !contactInfo.realUserId ? false : true">
                            <v-icon>mdi-plus</v-icon>Додати QR-код
                        </v-btn>

                    </v-card>
                </td>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn icon title="Редагувати" @click="editCard(item)" v-if="$vuetify.breakpoint.smAndDown" :disabled="item.status == 'closed' || contactInfo.realUserId ? true : false">
                    <v-icon class="green--text">mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon title="Закрити" v-if="item.status == 'active'" :disabled="contactInfo.realUserId ? true : false" @click="closePointD(item)">
                    <v-icon class="link--text">mdi-eye-outline</v-icon>
                </v-btn>
                <v-btn icon title="Відкрити" v-if="item.status == 'closed'" :disabled="contactInfo.realUserId ? true : false" @click="openPointD(item)">
                    <v-icon>mdi-eye-off-outline</v-icon>
                </v-btn>
            </template>
            <template v-slot:item.edit="{ item }">
                <v-btn icon title="Редагувати" @click="editCard(item)" v-if="$vuetify.breakpoint.mdAndUp" :disabled="item.status == 'closed' || contactInfo.realUserId ? true : false">
                    <v-icon class="green--text">mdi-pencil</v-icon>
                </v-btn>
            </template>
            <template v-slot:item.address="{ item }">
                {{ item.pindex ? `${item.pindex}, ` : `` }} {{ item.city_name }}, {{ item.street_name }}, {{ item.building }}
                <div v-if="item.address">(Дод. до адреси: {{ item.address }})</div>
            </template>
            <template v-slot:item.phone="{ item }">
                <div class="text-truncate">{{ item.phone }}</div>
            </template>
            <template v-slot:item.type="{ item }">
                <v-chip :color="item.type == 'company' ? 'link' : ''" small outlined>{{ item.type == 'company' ?
                        'Drop-shipping' : 'Клієнт'}}</v-chip>
            </template>
        </v-data-table>
        <v-card-actions>
            <v-btn :disabled="contactInfo.realUserId ? true : false" @click="editCard()">Додати точку</v-btn>
        </v-card-actions>
    </v-card>

    <v-dialog v-model="dialog" persistent width="700" :fullscreen="$vuetify.breakpoint.mdAndUp ? false : true">
        <v-system-bar dark>
            <v-spacer></v-spacer>
            <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-system-bar>
        <v-card>
            <v-form v-model="form" >
            <v-card-title class="headline">
                Картка Точки доставки
            </v-card-title>
            <v-card-text v-if="f.typhoon_id" class="text-right font-weight-bold">
                Номер: {{ f.id }}
            </v-card-text>
            <v-card-text v-if="!f.id">
                Перед додаванням нової точки доставки впевніться, що її ще немає у списку. Якщо ви її знайшли у
                закритих
                точках, то відкрийте її наново.
            </v-card-text>
            <!-- {{f}}  -->
            <v-alert type="info" text outlined dense v-if="f.typhoon_id" class="mx-4">
                Увага! Точка доставки внесена у облікову систему.<br>
                Доступні для редагування лише контактні реквізити.<br>
                Якщо точка неактуальна, то Ви можете її закрити.<br>

            </v-alert>
            <div class="mx-4">
                <v-autocomplete v-model="f.type" outlined :items="typeItems" :disabled="disabledItem()" label="Тип *"></v-autocomplete>
                <v-text-field outlined label="Назва *:" :disabled="disabledItem()" v-model="f.consignee_name"></v-text-field>
                <v-autocomplete v-model="f.contractor_id" item-text="name" :disabled="disabledItem()" item-value="id" outlined :items="contractors" label="Контрагент"></v-autocomplete>

                <v-subheader class="text-subtitle-2">Адреса</v-subheader>
                <v-autocomplete v-model="f.region" outlined :items="regions" :disabled="disabledItem()" @change="getCities()" label="Область *"></v-autocomplete>
                <v-text-field outlined label="Індекс:" placeholder="00000" v-mask="indexMask" v-model="f.pindex"></v-text-field>
                <v-autocomplete v-model="f.city" outlined auto-select-first :disabled="disabledItem()" :filter="customFilter" :items="cities" label="Місто *"></v-autocomplete>
                <v-autocomplete v-model="f.street" auto-select-first outlined :disabled="disabledItem()" :search-input.sync="search" :items="streets" label="Вулиця *" no-data-text="Введіть назву вулиці"></v-autocomplete>
                <v-text-field outlined label="Будинок *:" :disabled="disabledItem()" :rules="[rules.noSpaces, rules.building]" v-model="f.building"></v-text-field>
                <v-text-field outlined label="Дод. до адреси:" v-model="f.address"></v-text-field>
                <v-subheader class="text-subtitle-2">Контактна особа</v-subheader>
                <v-text-field outlined label="Прізвище *:" :rules="[rules.noSpaces, rules.noDigits]" v-model="f.surname"></v-text-field>
                <v-text-field outlined label="Ім'я  *:" :rules="[rules.noSpaces, rules.noDigits]" v-model="f.first_name"></v-text-field>
                <v-text-field outlined label="По-батькові *:" :rules="[rules.noSpaces, rules.noDigits]" v-model="f.second_name"></v-text-field>
                <v-text-field outlined label="Тел. *:" v-model="f.phone" placeholder="+38(000) 000 - 0000" v-mask="maskTel"></v-text-field>
            </div>

            <v-alert type="warning" text outlined dense class="mx-4">
                Увага! Усі поля позначені зірочкою, обов'язкові до заповнення!
            </v-alert>
            <v-card-actions>
                <v-btn color="primary" text :loading="loading" :disabled="!checkForm" @click="submitForm()">
                    Зберегти
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn text @click="dialog = false">Закрити</v-btn>
            </v-card-actions>
            <v-fade-transition>
                <v-overlay :value="overlay" absolute opacity='0.25'>
                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                </v-overlay>
            </v-fade-transition>
            </v-form>
        </v-card>
    </v-dialog>
    <v-dialog v-model="closePointDialod" width="600" max-width="100%">
        <v-card width="100%">
            <v-card-title class="warning white--text">Увага!</v-card-title>
            <v-card-text class="mt-6">
                <p>Ви впевнені, що точка доставки вже втратила свою актуальність і її потрібно закрити?</p>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" :loading="deliveryLoading" @click="closePoint()">
                    підтвердити
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="closePointDialod = false">
                    Скасувати
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="openPointDialod" width="600" max-width="100%">
        <v-card width="100%">
            <v-card-title class="warning white--text">Увага!</v-card-title>
            <v-card-text class="mt-6">
                <p>Повернути точку доставки в роботу?</p>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" :loading="deliveryLoading" @click="openPoint()">
                    підтвердити
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="openPointDialod = false">
                    Скасувати
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="openRecipientDialog" width="600" max-width="100%">
        <v-system-bar dark>
            <v-spacer></v-spacer>
            <v-icon @click="openRecipientDialog = false">mdi-close</v-icon>
        </v-system-bar>
        <v-card>
            <v-card-title class="headline">
                Довірений одержувач на точці:
            </v-card-title>
            <v-card-text class="mt-6">
                <v-text-field outlined label="Прізвище *:" :rules="[rules.noSpaces, rules.noDigits]" v-model="recipient.surname"></v-text-field>
                <v-text-field outlined label="Ім'я  *:" :rules="[rules.noSpaces, rules.noDigits]" v-model="recipient.first_name"></v-text-field>
                <v-text-field outlined label="По-батькові *:" :rules="[rules.noSpaces, rules.noDigits]" v-model="recipient.second_name"></v-text-field>
                <v-text-field outlined label="Посада *:" v-model="recipient.position"></v-text-field>
                <v-text-field outlined label="Тел. *:" v-model="recipient.phone" placeholder="+38(000) 000 - 0000" v-mask="maskTel"></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" text :disabled="!checkRecipientForm" :loading="addRecipientLoading" @click="addRecipient()">
                    <v-icon class="mr-2">mdi-qrcode-plus</v-icon>
                    Додати
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn text @click="openRecipientDialog = false">
                    Скасувати
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import {
    mapActions
} from 'vuex'

import {
    mask
} from 'vue-the-mask'

const buildingPattern = /^[1-9][0-9]*(?:[А-Я](?:\/[0-9]+[А-Я]?)?|\/[0-9]+[А-Я]?)?$/u

export default {
    components: {},
    data: () => ({
        show: false,
        loading: false,
        loaded: false,
        dialog: false,
        overlay: false,
        idForClose: null,
        idForOpen: null,
        openPointDialod: false,
        closePointDialod: false,
        deliveryLoading: false,
        typeItems: [{
                value: 'company',
                text: 'Drop-shipping'
            },
            {
                value: 'person',
                text: 'Клієнт'
            }
        ],
        f: {},
        form: null,
        recipient: {},
        search: '',
        expanded: [],
        openRecipientDialog: false,
        activeConsignee: null,
        addRecipientLoading: false,
        maskTel: '+38# ## ### ####',
        indexMask: '#####',
        mime: {
            xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
            xml: "application/xml",
            html: "text/html",
            pdf: "application/pdf"
        },
        fileBlobURL: '',
        loadingPdf: {},
        headers: [{
                text: '',
                align: 'left',
                sortable: false,
                value: 'actions',
                width: '2%',
                selected: true,
            },
            {
                text: 'Номер',
                align: 'left',
                sortable: true,
                value: 'id',
                width: '6%',
                selected: true,
            },
            {
                text: 'Тип',
                align: 'center',
                value: 'type',
                selected: true,
                width: '10%',
            },
            {
                text: 'Назва',
                align: 'center',
                value: 'name',
                selected: true,
                width: '20%',
            },
            {
                text: 'Область',
                align: 'center',
                value: 'region',
                selected: true,
                width: '15%',
            },
            {
                text: 'Адреса',
                align: 'center',
                value: 'address',
                selected: true,
                width: '25%',
            },
            {
                text: 'ПІБ',
                align: 'center',
                value: 'fio',
                selected: true,
                width: '15%',
            },
            {
                text: 'Телефон',
                align: 'right',
                value: 'phone',
                selected: true,
                width: '10%',
            },
            {
                text: '',
                value: 'data-table-expand',
                align: 'left',
                selected: true,
                sortable: false,
                width: '2%',
            },

        ],
        rules: {
            noSpaces: value => {
                const pattern = /\s+/
                return pattern.test(value) ? 'У тексті не повинно бути пробілів ' : true
            },
            noDigits: value => {
                const pattern = /\d+/
                return pattern.test(value) ? 'У тексті не повинно бути цифр ' : true
            },
            building: value => {
                return !value || buildingPattern.test(value) ? true : 'Номеру будинку має містити лише цифри (0-9), великі букви А-Я, символ "/", та починатися з цифри (1-9)'
            }
        }
    }),
    directives: {
        mask
    },
    watch: {
        search(val) {
            val && val !== this.select && this.querySelections(val)
        },
    },

    methods: {
        ...mapActions(['touch', 'getContactInfo', 'getCitiesAutocomplete', 'getRegionsAutocomplete', 'setSnackbar',
            'getStreetsAutocomplete', 'getOrderContractors', 'submitConsigneeData', 'getConsigneesData', 'getConsigneesCardData', 'closeDeliveryPoint', 'openDeliveryPoint',
            'addRecipientData', 'getConsigneesRecipients', 'extendRecipientData', 'cancelRecipientData', 'cancelAllConsigneeRecipientData', 'createConsigneeRecipientPdf'
        ]),
        getCities() {
            this.overlay = true
            this.getCitiesAutocomplete(this.f.region)
                .then(() => {
                    this.overlay = false
                })
        },
        querySelections(v) {
            this.overlay = true
            this.getStreetsAutocomplete({
                    cityId: this.f.city,
                    keyword: v
                })
                .then(() => {
                    this.overlay = false
                })
        },
        submitForm() {
            this.loading = true
            this.$set(this.f, 'surname', this.f.surname.trim())
            this.$set(this.f, 'first_name', this.f.first_name.trim())
            this.$set(this.f, 'second_name', this.f.second_name.trim())
            this.submitConsigneeData(this.f)
                .then(() => {
                    this.setSnackbar(['success', 'Данні прийняті. Зміни будуть відображені в обліковій системі протягом 30 хвилин.'])
                    this.getConsigneesData()
                    this.loading = false
                    this.dialog = false
                })
                .catch(error => {
                    console.error(error)
                    this.loading = false
                    if (error.response && error.response.status == 403) {
                        this.setSnackbar(['error', 'Точка доставки с такими реквізитами вже існує.'])
                    } else {
                        this.setSnackbar(['error', ''])
                    }
                })
        },
        editCard(item) {
            this.dialog = true
            this.overlay = true
            this.f = {}
            this.$store.commit('setStreets', [])

            if (item && item.id) {
                this.getConsigneesCardData(item.id)
                    .then(data => {
                        this.f = data
                        this.getCitiesAutocomplete(data.region)
                            .then(() => {
                                this.$set(this.f, 'city', data.city_id)
                                this.$set(this.f, 'street', String(data.street))
                                this.getStreetsAutocomplete({
                                        cityId: this.f.city,
                                        streetId: this.f.street,
                                    })
                                    .then(() => {
                                        // this.search = this.streets[0].text
                                        this.overlay = false
                                    })
                            })
                    })
            } else {
                this.overlay = false
            }
        },
        disabledItem() {
            return this.f.typhoon_id ? true : false
        },
        openPoint() {
            this.deliveryLoading = true
            this.openDeliveryPoint(this.idForOpen)
                .then(() => {
                    this.openPointDialod = false
                    this.deliveryLoading = false
                    this.setSnackbar(['success', 'Данні прийняті. Зміни будуть відображені в обліковій системі протягом 30 хвилин.'])
                    this.getConsigneesData()
                        .then(() => this.getConsigneesRecipients())
                })
                .catch(error => console.log(error))
        },
        openPointD(item) {
            this.idForOpen = item.id
            this.openPointDialod = true
        },
        closePoint() {
            this.deliveryLoading = true
            this.closeDeliveryPoint(this.idForClose)
                .then(() => {
                    this.closePointDialod = false
                    this.deliveryLoading = false
                    this.setSnackbar(['success', 'Данні прийняті. Зміни будуть відображені в обліковій системі протягом 30 хвилин.'])
                    this.getConsigneesData()
                        .then(() => {
                            this.cancelAllConsigneeRecipientData({
                                    id: this.idForClose
                                })
                                .then(() => {
                                    this.expanded = this.expanded.filter(item => item.id !== this.idForClose)
                                    this.getConsigneesRecipients()
                                })
                        })
                })
                .catch(error => console.log(error))
        },
        closePointD(item) {
            this.idForClose = item.id
            this.closePointDialod = true
        },
        rowClasses(item) {
            if (item.status == 'closed') {
                return "closedRow"
            }
        },
        customFilter(item, queryText, itemText) {
            const textOne = item.text.toLowerCase()
            const textTwo = item.name.toLowerCase()
            const searchText = queryText.toLowerCase()

            return textOne.indexOf(searchText) > -1 ||
                textTwo.indexOf(searchText) > -1
        },
        openRecipient(id) {
            this.openRecipientDialog = true
            this.activeConsignee = id
            this.recipient = {}
        },
        addRecipient() {
            this.addRecipientLoading = true
            this.addRecipientData({
                    ...this.recipient,
                    consignee_id: this.activeConsignee
                })
                .then(() => {
                    this.openRecipientDialog = false
                    this.addRecipientLoading = false
                    this.setSnackbar(['success', 'Одержувача створено'])
                    this.getConsigneesData()
                    this.getConsigneesRecipients()
                })
                .catch(error => {
                    this.addRecipientLoading = false
                    console.error(error)
                    this.setSnackbar(['error', ''])
                })
        },
        extendRecipient(id) {
            this.extendRecipientData({
                    id
                })
                .then(() => {
                    this.setSnackbar(['success', 'Термін дії продовжено'])
                    this.getConsigneesRecipients()
                })
                .catch(error => {
                    console.error(error)
                    this.setSnackbar(['error', ''])
                })
        },
        cancelRecipient(id) {
            this.cancelRecipientData({
                    id
                })
                .then(() => {
                    this.setSnackbar(['success', 'Права скасовано'])
                    this.getConsigneesRecipients()
                })
                .catch(error => {
                    console.error(error)
                    this.setSnackbar(['error', ''])
                })
        },
        getRecipientPdf(recipient, consignee) {
            window.URL.revokeObjectURL(this.fileBlobURL); //очистить память от ранее принятого файла
            this.$set(this.loadingPdf, recipient.id, true)
            this.touch()
                .then(() => {
                    this.createConsigneeRecipientPdf({
                            recipient,
                            consignee
                        })
                        .then(data => {
                            this.saveData(data, 'qr-code.pdf')
                            this.setSnackbar(['success', 'QR-код завантажено'])
                            this.$set(this.loadingPdf, recipient.id, false)
                        })
                })
                .catch(error => console.error(error))
        },
        async saveData(data, fileName, format) {
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            var buf = Buffer.from(data, 'base64')
            const blob = new Blob([buf], {
                type: this.mime['pdf']
            })
            let url = window.URL.createObjectURL(blob);
            this.fileBlobURL = a.href = url;
            a.download = fileName;
            a.click();
        },
        toggleExpand(it, isExpanded, expand) {
            const id = it.id
            if (isExpanded) {
                // Видалити з expanded по id
                this.expanded = this.expanded.filter(item => item.id !== id)
            } else {
                // Розгорнути
                if (it.status == 'active') {
                    expand()
                }
            }
        },
    },
    computed: {
        contactInfo() {
            return this.$store.state.main.contactInfo
        },
        regions() {
            return this.$store.state.order.regions
        },
        cities() {
            return this.$store.state.order.cities
        },
        streets() {
            return this.$store.state.order.streets
        },
        contractors() {
            return this.$store.state.order.contractors
        },
        consigneesData() {
            return this.$store.state.order.consigneesData
        },
        consigneesRecipients() {
            return this.$store.state.order.consigneesRecipientsObj
        },
        checkForm() {
            
            return this.form &&
                this.f.type &&
                this.f.consignee_name &&
                this.f.region &&
                this.f.city &&
                this.f.street &&
                this.f.building &&
                buildingPattern.test(this.f.building) &&
                this.f.surname && !/(\s)+/.test(this.f.surname) &&
                this.f.first_name && !/\s+/.test(this.f.first_name) &&
                this.f.second_name && !/\s+/.test(this.f.second_name) &&
                (this.f.phone && this.f.phone.length == 16 ? true : false) &&
                (this.f.pindex && this.f.pindex.length !== 5 ? false : true)
        },
        filteredHeaders() {
            return this.headers.filter(h => {
                return h.selected && !h.hidden
            })
        },
        checkRecipientForm() {
            return this.recipient.surname && !/\s+/.test(this.recipient.surname) &&
                this.recipient.first_name && !/\s+/.test(this.recipient.first_name) &&
                this.recipient.second_name && !/\s+/.test(this.recipient.second_name) &&
                this.recipient.position &&
                (this.recipient.phone && this.recipient.phone.length == 16 ? true : false)
        },
    },
    created() {
        this.touch()
            .then(() => {
                this.getOrderContractors()
                    .then(() => this.loaded = true)
                this.getContactInfo()
                    .then(d => {
                        if (!d.isSellToEmployee && d.groups && d.groups.cladmin_delivRecipient) {
                            this.show = true
                            this.getRegionsAutocomplete()
                            this.getConsigneesData()
                                .then(() => {
                                    this.getConsigneesRecipients()
                                })

                        } else {
                            this.$router.push('/403')
                        }
                    })
            })
            .catch(error => console.log(error))

    }
}
</script>

<style>
.v-application--is-ltr .v-list-group--sub-group .v-list-group__header {
    padding-left: 0;
}

.closedRow {
    background-color: rgb(196, 196, 196);
    color: grey;
}
</style>
